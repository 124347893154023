import React, { createContext, useReducer } from 'react';
import { LoginState, LoginAction } from '../types';
import { loginReducer } from '../functions';

interface ContextProps {
  state: LoginState;
  dispatch: (action: LoginAction) => void;
}

const defaults: LoginState = localStorage.getItem('context')
  ? { ...JSON.parse(localStorage.getItem('context')!), loggedIn: true }
  : {
      loggedIn: false,
      userId: '',
      token: '',
    };
export const LoginContext = createContext({} as ContextProps);

export const LoginContextProvider = (props: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(loginReducer, defaults);
  return (
    <LoginContext.Provider value={{ state, dispatch }}>
      {props.children}
    </LoginContext.Provider>
  );
};
