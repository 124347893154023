import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  LoginForm,
  LoginContextProvider,
  // RegistrationForm,
  LoginContainer,
  AuthPage,
} from './auth';
import { Header } from './nav';
import { Col } from 'react-bootstrap';

// page uses the hook
function LoggedInPage() {
  return (
    <>
      <Header />

      <div className='App' data-testid='logged-in-app'>
        <div className='AppContainer'>
          <Col md={{ span: 10, offset: 2 }} className=''>
            {/* <Switch>
              <Route path='/register' component={RegistrationForm} />
            </Switch> */}
          </Col>
        </div>
      </div>
    </>
  );
}

function LoggedOutPage() {
  return (
    <>
      <div className='App' data-testid='logged-out-app'>
        <div className='AppContainer'>
          {/* <Header /> */}
          <Switch>
            <Route exact path='/' component={AuthPage} />
            {/* <Route path='/register' component={RegistrationForm} /> */}
          </Switch>
        </div>
      </div>
    </>
  );
}

// loading component for suspense fallback
const Loader = () => (
  <div className='App'>
    <div>loading...</div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <LoginContextProvider>
        <Router>
          <LoginContainer
            isLoggedIn={<LoggedInPage />}
            willLogIn={<LoggedOutPage />}
          />
        </Router>
      </LoginContextProvider>
    </Suspense>
  );
}
