import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import '../login.css';

/**
 * @description Component Logs user into the system.
 * @output Renders two input fields take in email and password,
 * saves them to state,  and calls back-end API for user Login.
 * Then it calls context-dispatch that updates the context, and saves resulting token/hash (currently hardcoded) in sessionsStorage
 */

export function RegistrationForm({ setLoggedIn }: any) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const userData = {
      name: name,
      email: email,
      password: password,
      password2: confirmPassword,
    };
    if (userData) {
      axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/register`,
        userData
      );
    }
    setValidated(true);
  };

  return (
    <>
      <div className='form-container sign-up-container'>
        <div className='form'>
          <h1>Create Account</h1>
          <Form noValidate validated={validated}>
            <Form.Group controlId='name'>
              <Form.Control
                className='input'
                type='name'
                required
                placeholder={t('common.fullNamePlaceholder')}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <Form.Control.Feedback type='invalid'>
                Please enter a name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='email'>
              <Form.Control
                className='input'
                type='email'
                required
                placeholder={t('common.emailPlaceholder')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Text className='text-muted'>
                {t('auth.loginFormText')}
              </Form.Text>
              <Form.Control.Feedback type='invalid'>
                Please enter a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='password'>
              <Form.Control
                className='input'
                type='password'
                required
                placeholder={t('common.passwordPlaceholder')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Form.Control.Feedback type='invalid'>
                Please enter your password.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='confirmPassword'>
              <Form.Control
                className='input'
                type='confirmPassword'
                required
                placeholder={t('common.confirmPasswordPlaceholder')}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Form.Control.Feedback type='invalid'>
                Please enter your password again.
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
          <button
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
    </>
  );
}
