import React, { useState } from 'react';
import './login.css';
import { LoginForm, RegistrationForm } from './components';

/**
 * @description Component Logs user into the system.
 * @output Renders two input fields take in email and password,
 * saves them to state,  and calls back-end API for user Login.
 * Then it calls context-dispatch that updates the context, and saves resulting token/hash (currently hardcoded) in sessionsStorage
 */

export function AuthPage({ setLoggedIn }: any) {
  const [containerClass, setContainerClass] = useState('container');

  return (
    <>
      <div className='mainContainer'>
        <div className={containerClass}>
          <RegistrationForm />
          <LoginForm />
          <div className='overlay-container'>
            <div className='overlay'>
              <div className='overlay-panel overlay-left'>
                <h1>Already Registered?</h1>
                <br />
                {/* <p>
                  To keep connected with us please login with your personal info
                </p> */}
                <button
                  onClick={(e) =>
                    setContainerClass('container left-panel-active')
                  }
                  className='ghost'
                  id='signIn'
                >
                  Sign In
                </button>
              </div>
              <div className='overlay-panel overlay-right'>
                <h1>Book A Shaadi, Now!</h1>
                <br />
                {/* <p>Enter your personal details and start journey with us</p> */}
                <button
                  onClick={(e) =>
                    setContainerClass('container right-panel-active')
                  }
                  className='ghost'
                  id='signUp'
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
