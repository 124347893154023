import React, { useContext } from 'react';
import { LoginContext } from '..';

export const LoginContainer = (props: {
  isLoggedIn: React.ReactNode;
  willLogIn: React.ReactNode;
}) => {
  const { state } = useContext(LoginContext);
  return (
    <div data-testid='login-container'>
      {state.loggedIn || state.token ? props.isLoggedIn : props.willLogIn}
    </div>
  );
};
