import React, { useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * @description Component Switches Language
 * @output Renders two buttons that switch language between English and Swedish
 */

export function ToggleLocale() {
  const { t, i18n } = useTranslation();
  const [activeOption, setActiveOption] = useState('');
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const popover = (
    <Popover id='popover-basic'>
      <Popover.Title as='h3'>{t('description.part1')}</Popover.Title>
      <Popover.Content>{t('description.part2')}</Popover.Content>
    </Popover>
  );
  const onClickHandler = (option: string) => {
    changeLanguage(option);
    setActiveOption(option);
  };
  return (
    <Col>
      <DropdownButton
        key='left'
        id='dropdown-button-drop-direction'
        drop='left'
        variant='dark'
        title='Change Locale'
      >
        <OverlayTrigger placement='bottom' overlay={popover}>
          <Col className='m-2'>
            <Dropdown.Item
              className={activeOption === 'sw' ? 'active-locale-button' : ''}
              onClick={() => onClickHandler('sw')}
            >
              sw
            </Dropdown.Item>
            <Dropdown.Item
              className={activeOption === 'en' ? 'active-locale-button' : ''}
              onClick={() => onClickHandler('en')}
            >
              en
            </Dropdown.Item>
          </Col>
        </OverlayTrigger>
      </DropdownButton>
    </Col>
  );
}
