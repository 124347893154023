import { LoginState, LoginAction } from '../types';

export const loginReducer = (
  state: LoginState,
  action: LoginAction
): LoginState => {
  switch (action.type) {
    case 'login':
      //set user-token to session storage
      localStorage.setItem('context', JSON.stringify(action.payload));
      return {
        ...state,
        loggedIn: true,
        userId: action.payload.userId,
        token: action.payload.token
      };
    case 'logout':
      //clear sessionStorage
      localStorage.clear();
      return {
        ...state,
        loggedIn: false,
        userId: '',
        token: null
      };
    default:
      throw new Error();
  }
};
