import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { LoginContext, LoginAction } from '..';
import axios from 'axios';
import '../login.css';

/**
 * @description Component Logs user into the system.
 * @output Renders two input fields take in email and password,
 * saves them to state,  and calls back-end API for user Login.
 * Then it calls context-dispatch that updates the context, and saves resulting token/hash (currently hardcoded) in sessionsStorage
 */

export function LoginForm() {
  const loginContext = useContext(LoginContext);
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const userData = {
      email: email,
      password: password,
    };
    if (userData) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/users/login`, userData)
        .then((res) => {
          const login: LoginAction = {
            type: 'login',
            payload: {
              userId: email,
              token: res.data.access_token,
            },
          };
          loginContext.dispatch(login);
        });
    }
    setValidated(true);
  };

  return (
    <>
      <div className='form-container sign-in-container'>
        <div className='form'>
          <h1>Sign In</h1>
          <Form noValidate validated={validated}>
            <Form.Group controlId='email'>
              <Form.Control
                className='input'
                type='email'
                required
                placeholder={t('common.emailPlaceholder')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Text className='text-muted'>
                {t('auth.loginFormText')}
              </Form.Text>
              <Form.Control.Feedback type='invalid'>
                Please enter a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='password'>
              <Form.Control
                className='input'
                type='password'
                required
                placeholder={t('common.passwordPlaceholder')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Form.Control.Feedback type='invalid'>
                Please enter your password.
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
          <a href='#'>Forgot your password?</a>
          <button
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Sign In
          </button>
        </div>
      </div>
    </>
  );
}
