import React, { useContext } from 'react';
import { Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LoginContext, LoginAction } from '../../auth';

import './header.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { ToggleLocale } from './toggle-locale';

export const Header = () => {
  const loginContext = useContext(LoginContext);
  const { t } = useTranslation();

  const logout: LoginAction = {
    type: 'logout',
  };

  const handleLogoutSubmit = (e: any) => {
    loginContext.dispatch(logout);
  };

  return (
    <LoginContext.Consumer>
      {(loginValues) => (
        <>
          <Navbar bg='light' variant='light' expand='lg'>
            <Navbar.Brand href='/landing'>Book-A-Shaadi</Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='mr-auto'>
                {/* <Nav.Link href='#home'>Home</Nav.Link>
                <Nav.Link href='#link'>Link</Nav.Link> */}
              </Nav>
              <Col xs={1} md={2} className='text-right setting-icon m-0 p-0'>
                <NavDropdown
                  title={<FontAwesomeIcon className='fa-icon' icon={faCog} />}
                  id='basic-nav-dropdown'
                >
                  <NavDropdown.Item href='/profile'>
                    {t('button.profile')}
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/' onClick={handleLogoutSubmit}>
                    {t('button.logout')}
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/changePassword'>
                    {t('button.changePassword')}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <ToggleLocale />
                </NavDropdown>
              </Col>
            </Navbar.Collapse>
          </Navbar>
        </>
      )}
    </LoginContext.Consumer>
  );
};
